import React, { useState } from 'react';
import './App.css';

function App() {
  const [text, setText] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // Index for navigating recommendations
  const [dropdownValue, setDropdownValue] = useState('select');
  const [loading, setLoading] = useState(false);
  const [distance, setDistance] = useState(0);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };

  const handlePredict = () => {
    setLoading(true);
    fetch('https://api.knowledgekeeper.ai/predict', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'User-Agent': 'insomnia/9.3.2',
        'x-api-key': 'Bearer a681787caab4a0798df5f33898416157dbfc50a65f49e3447d33fc7981920499'
      },
      body: JSON.stringify({
        input_text: text,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const recommendationsData = data.data;
        if (recommendationsData.length === 0 || recommendationsData[0].text_relevancy === 'irrelevant') {
          setLoading(false);
          alert('No possible edits');
          setRecommendations([]);
          setDistance(recommendationsData[0].distance);
        } else {
          setRecommendations(recommendationsData);
          setCurrentIndex(0); // Reset index to the first recommendation
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  };

  const handleNextRecommendation = () => {
    if (currentIndex < recommendations.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePreviousRecommendation = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleAddReview = () => {
    if (dropdownValue === 'SELECT') {
      alert('Please select a review option.');
      return;
    }

    const currentRecommendation = recommendations[currentIndex];

    const reviewData = {
      byte: text,
      relevancy: currentRecommendation.text_relevancy,
      prediction: currentRecommendation.prediction,
      distance: currentRecommendation.distance,
      review_comment: dropdownValue,
      generated_text: currentRecommendation.generated_text,
      splitted_content: currentRecommendation.splitted_content,
      section_content: currentRecommendation.section_content,
      section_main_heading1: currentRecommendation.section_main_heading1,
      section_main_heading2: currentRecommendation.section_main_heading2,
      section_main_heading3: currentRecommendation.section_main_heading3,
      section_main_heading4: currentRecommendation.section_main_heading4,
    };

    const existingReviews = JSON.parse(localStorage.getItem('reviews')) || [];
    existingReviews.push(reviewData);
    localStorage.setItem('reviews', JSON.stringify(existingReviews));

    alert('Review logged. Please remember to click Finish Review once complete.');
    // resetForm();
  };

  const handleFinishReview = () => {
    const storedReviews = JSON.parse(localStorage.getItem('reviews')) || [];

    if (storedReviews.length === 0) {
        alert('No reviews to upload.');
        return;
    }

    const payload = {
        html_file_name: "network_hardware.html",
        review_content: storedReviews.map(review => ({
            byte: review.byte,
            relevancy: review.relevancy,
            prediction: review.prediction,
            review_comment: review.review_comment,
            generated_text: review.generated_text,
            splitted_content: review.splitted_content,
            section_content: review.section_content,
            section_main_heading1: review.section_main_heading1,
            section_main_heading2: review.section_main_heading2,
            section_main_heading3: review.section_main_heading3,
            section_main_heading4: review.section_main_heading4,
            distance: review.distance
        }))
    };

    fetch('https://api.knowledgekeeper.ai/submit-review', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'User-Agent': 'insomnia/9.3.2',
            'x-api-key': 'Bearer a681787caab4a0798df5f33898416157dbfc50a65f49e3447d33fc7981920499'
        },
        body: JSON.stringify(payload)
    })
        .then((response) => response.text())
        .then((data) => {
          if(data){
            alert('Reviews submitted successfully');
          }else{
            alert('Error uploading reviews. Please contact admin');
          }
            localStorage.removeItem('reviews');
            resetForm();
        })
        .catch((error) => {
            console.error('Error uploading reviews:', error);
        });
  };

  const resetForm = () => {
    setText('');
    setRecommendations([]);
    setDropdownValue('select');
    setCurrentIndex(0);
  };


  const currentRecommendation = recommendations[currentIndex] || {};

  return (
    <div className="Container">
      <label className="Label">BYTE</label>
      <textarea
        className="TextEditor"
        placeholder="Enter text here..."
        value={text}
        onChange={handleTextChange}
      />
      <div className="ButtonGroupContainer">
        <div className="ButtonGroupLeft">
          <button className={`Button ${loading ? 'loading' : ''}`} onClick={handlePredict} disabled={loading}>
            Submit
            <div className="spinner"></div>
          </button>
        </div>
      </div>
      <div className="FormSection">
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <label className="LabelAI">AI RECOMMENDATION</label>&nbsp;
        </div>
        <textarea
          className="RecommendationTextArea"
          placeholder={`Title\nHeading 1\nHeading 2\nHeading 3`}
          value={
            `${currentRecommendation.prediction ? `Prediction: ${currentRecommendation.prediction}\n` : ''}` +
            `${currentRecommendation.section_main_heading1 || ''}\n` +
            `${currentRecommendation.section_main_heading2 || ''}`
          }          
          readOnly
        />
        <div className="SideBySideContainer">
          <div className="HalfWidthSection">
            <h4 className="RecommendationTitle">ORIGINAL</h4>
            <textarea
              className="RecommendationTextArea"
              value={currentRecommendation.splitted_content ? `Section Content:\n ${currentRecommendation.section_content}\nSplitted Content:\n${currentRecommendation.splitted_content}`|| '' : ''}
              readOnly
              placeholder="Actual Content"
            />
          </div>
          <div className="HalfWidthSection">
            <h4 className="RecommendationTitle">EDIT</h4>
            <textarea
              className="RecommendationTextArea"
              value={currentRecommendation.generated_text || ''}
              readOnly
              placeholder="New Content"
            />
          </div>
        </div>
        <br />
        <div className="NavigationButtons">
          <button className='AdditionalButtons' onClick={handlePreviousRecommendation} disabled={currentIndex === 0}>Previous</button>
          &nbsp;
          <button className='AdditionalButtons' onClick={handleNextRecommendation} disabled={currentIndex === recommendations.length - 1}>Next</button>
          &nbsp;
          <button className="AdditionalButtons" onClick={resetForm}>
            Clear
          </button>
        </div>
      </div>
      <div className="DropdownContainer">
        <select className="Dropdown" value={dropdownValue} onChange={handleDropdownChange}>
          <option value="select">SELECT</option>
          <option value="accept">Accept</option>
          <option value="reject">Reject</option>
          <option value="not_relevant">Not Relevant</option>
        </select>
      </div>
      <div className="ButtonGroupContainer">
        <div className="ButtonGroupReview">
          <button className="Button" onClick={handleAddReview} disabled={dropdownValue === 'select'}>
            Add Review
          </button>
          <button className="Button" onClick={handleFinishReview}>
            Finish Review
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;